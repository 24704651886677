<style >
	.iconfont.iconfont-bg {
		font-size: 16px;
		font-weight: 500;
		/* color: rgba(255, 255, 255, 0.5); */
		/* float: right;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																		transform: rotate(30deg);
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																		position: relative;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																		right: -20px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																		bottom: -10px; */
	}
	.iconfont-bg {
		font-size: 16px;
		font-weight: 500;
		/* color: rgba(255, 255, 255, 0.5); */
		/* float: right;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																		transform: rotate(30deg);
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																		position: relative;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																		right: -20px;
																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																		bottom: -10px; */
	}
	.scard {
		background: url(../../assets/border1.png) no-repeat center center;
		background-size: 100% 100%;
		padding: 20px;
	}
	.scard2 {
		background: url(../../assets/border2.png) no-repeat center center;
		background-size: 100% 100%;
		padding: 20px;
	}
	.scard3 {
		background: url(../../assets/border3.png) no-repeat center center;
		background-size: 100% 100%;
		padding: 10px;
	}
	.scard5 {
		background: url(../../assets/border5.png) no-repeat center center;
		background-size: 100% 100%;
		/* background: transparent; */
		padding: 10px;
	}
	.scard3 .el-table th,
	.scard3 .el-table tr,
	.scard3 td {
		background: transparent;
		border: 0;
		color: #fff;
	}
	.scard3 .el-table--enable-row-hover .el-table__body tr:hover > td {
		background-color: transparent !important;
	}
	.scard3 .el-table::before {
		background: transparent;
	}
	.snumber {
		background: url(../../assets/num2.png) no-repeat center center;
		background-size: 100% auto;
		height: 74px;
		padding-top: 40px;
	}
</style>
<template>

	<div style="background:#081c35;width:100%;min-height:100%">
		<el-container>
			<el-aside width="380px" style="padding:10px">
				<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px "> 平台信息概况 <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
				<div class="scard">
					<el-row :gutter="10" style="margin-bottom:10px">
						<el-col :span="12">
							<el-card style="border:0;background:#00b2b2" shadow="always" :body-style="{padding:0}">
								<h4 style="text-align:center;padding:10px 0;margin:0;color:#fff"> {{total[0]}} <span style="font-size:10px">家</span></h4>
								<p style="background:#009896;padding:5px 0;text-align:center;margin:0;color:#ffffffcc;font-size:14px"><i class="iconfont-bg el-icon-office-building" style="font-size:14px"></i> 接入企业 </p>
							</el-card>
						</el-col>
						<el-col :span="12">
							<el-card style="border:0;background:#2b8bf2" shadow="always" :body-style="{padding:0}">
								<h4 style="text-align:center;padding:10px 0;margin:0;color:#fff"> {{total[1]}} <span style="font-size:10px">个</span></h4>
								<p style="background:#2576d0;padding:5px 0;text-align:center;margin:0;color:#ffffffcc;font-size:14px"><i class="iconfont-bg el-icon-monitor" style="font-size:14px"></i> 监测点 </p>
							</el-card>
						</el-col>
					</el-row>
					<el-row :gutter="10" style="margin-bottom:10px">
						<el-col :span="12">
							<el-card style="border:0;background:#fb6952" shadow="always" :body-style="{padding:0}">
								<h4 style="text-align:center;padding:10px 0;margin:0;color:#fff"> {{total[2]}} <span style="font-size:10px">条</span></h4>
								<p style="background:#d15944;padding:5px 0;text-align:center;margin:0;color:#ffffffcc;font-size:14px"><i class="iconfont iconfont-bg el-icon-icon-alarm" style="font-size:14px"></i> 报警数(日) </p>
							</el-card>
						</el-col>
						<el-col :span="12">
							<el-card style="border:0;background:#6f6cd5" shadow="always" :body-style="{padding:0}">
								<h4 style="text-align:center;padding:10px 0;margin:0;color:#fff"> {{total[6]}} <span style="font-size:10px">吨标准煤</span></h4>
								<p style="background:#5f5cb5;padding:5px 0;text-align:center;margin:0;color:#ffffffcc;font-size:14px"> <i class="iconfont-bg el-icon-odometer" style="font-size:14px"></i> 综合能源消费量 </p>
							</el-card>
						</el-col>
					</el-row>
				</div>

				<div class="scard2" style="margin-top:20px;">
					<el-row :gutter="10">
						<el-col :span="8">
							<div class="snumber">
								<p style="text-align:center;margin:0;top:30px;color:#fff">{{total[4]}} </p>
								<p style="text-align:center;margin:0;color:#ffffffcc;font-size:12px"> 最大功率 kW</p>

							</div>
						</el-col>
						<el-col :span="8">
							<div class="snumber">
								<p style="text-align:center;margin:0;top:30px;color:#fff">{{total[5]}} </p>
								<p style="text-align:center;margin:0;color:#ffffffcc;font-size:12px">实时功率 kW</p>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="snumber">
								<p style="text-align:center;margin:0;top:30px;color:#fff">{{total[3]}} </p>
								<p style="text-align:center;margin:0;color:#ffffffcc;font-size:12px"> 总电量 kWh</p>
							</div>
						</el-col>
					</el-row>
				</div>
				<h4 style="color:#fff;line-height:44px;margin:0;margin-top:20px;font-weight:400"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 5px "> 电量能耗排名(月) <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
				<div class="scard3" style="height:250px;padding:30px 20px">
					<div style="height:235px;overflow-y:scroll">
						<el-table :highlight-current-row="false" :stripe="false" tooltip-effect="dark" :data="rank_device_month" :show-header="false" style="background: transparent;">
							<el-table-column center="center" width="40">
								<template slot-scope="scope">
									<div style="background:#2C6DD2;color:#fff;width: 20px;height: 20px;font-size: 14px;line-height: 20px;text-align: center;border-radius: 20px;">{{scope.row.rank_id}}</div>
								</template>
							</el-table-column>
							<el-table-column>
								<template slot-scope="scope">
									<p style="margin:0">{{scope.row.name}}</p>
									<span style="margin:0;font-size:12px;color:#2C6DD2;">
										<small style="font-size:16px;">{{scope.row.quantity}}</small> kWh
									</span>
									<el-progress :percentage="scope.row.rate" :show-text="false" />
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-aside>
			<el-main>
				<indexmap id="echart_map" :height_val="455" @tofactory="to_factory" />
				<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px "> 电量能耗趋势(月) <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
				<el-card class="scard5" shadow="never" :body-style="{paddingTop:0}" style="border:0;">
					<mechart id="echart1" title="" subtitle="" :style="'height:270px'" :options="echart1_opts" />
				</el-card>
			</el-main>
			<el-aside width="380px" style="padding:10px">
				<h4 style="color:#fff;line-height:44px;margin:0;font-weight:400"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 15px "> 电量能耗趋势(日) <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
				<el-card class="scard" shadow="never" :body-style="{paddingTop:0}" style="border:0;">
					<mechart id="echart0" title="" subtitle="" :style="'height: 166px'" :options="echart0_opts" />
				</el-card>
				<!-- <h4 style="color:#fff;line-height:44px;margin:0;font-weight:400"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 5px "> 分时电量(日) <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
				<div class="scard3">
					<mechartpie id="echart8" :style="'height:200px'" :options="echart8_opts" />
				</div> -->
				<!-- <div class="scard2" style="margin-top:20px;">
					<el-row :gutter="10">
						<el-col :span="8">
							<div class="snumber">
								<h1 style="text-align:center;margin:0;top:30px;font-size:16px;color:#fff">{{total[7]}} </h1>
								<p style="text-align:center;margin:0;color:#ffffffcc;font-size:12px"> 峰电量 kWh</p>

							</div>
						</el-col>
						<el-col :span="8">
							<div class="snumber">
								<h1 style="text-align:center;margin:0;top:30px;font-size:16px;color:#fff">{{total[8]}} </h1>
								<p style="text-align:center;margin:0;color:#ffffffcc;font-size:12px"> 平电量 kWh</p>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="snumber">
								<h1 style="text-align:center;margin:0;top:30px;font-size:16px;color:#fff">{{total[9]}} </h1>
								<p style="text-align:center;margin:0;color:#ffffffcc;font-size:12px"> 谷电量 kWh</p>
							</div>
						</el-col>
					</el-row>
				</div> -->
				<h4 style="color:#fff;line-height:44px;margin:0;margin-top:20px;font-weight:400"><img src="../../assets/dot.png" alt="" style="height:16px;margin:0 5px 0 5px "> 电量能耗排名(日) <img src="../../assets/line.png" alt="" style="float:right;margin-top:18px;height:9px"></h4>
				<div class="scard3" style="height:414px;padding:30px 20px">
					<div style="height:400px;overflow-y:scroll">
						<el-table :highlight-current-row="false" :stripe="false" tooltip-effect="dark" :data="rank_device_date" :show-header="false" style="background: transparent;">
							<el-table-column center="center" width="40">
								<template slot-scope="scope">
									<div style="background:#2C6DD2;color:#fff;width: 20px;height: 20px;font-size: 14px;line-height: 20px;text-align: center;border-radius: 20px;">{{scope.row.rank_id}}</div>
								</template>
							</el-table-column>
							<el-table-column>
								<template slot-scope="scope">
									<p style="margin:0">{{scope.row.name}}</p>
									<span style="margin:0;font-size:12px;color:#2C6DD2;">
										<small style="font-size:16px;">{{scope.row.quantity}}</small> kWh
									</span>
									<el-progress :percentage="scope.row.rate" :show-text="false" />
								</template>
							</el-table-column>
						</el-table>
					</div>
				</div>
			</el-aside>
		</el-container>
	</div>
</template>
<script>
	import request from "../../plugins/axios";
	import indexmap from "./page/index_map.vue";
	import mechart from "../../components/mechart.vue";
	import mechartpie from "../../components/mechart_pie.vue";
	export default {
		name: "App",
		data() {
			return {
				company_id: 1,
				// clientHeight: "", //页面高度
				role: window.localStorage.getItem("role"),
				nickname: window.localStorage.getItem("nickname"),
				head: window.localStorage.getItem("head"),
				total: [], //总数
				rank_device_date: [], //电量排名
				rank_device_month: [],
				echart0_opts: {
					// backgroundColor: "rgba(0,0,0,0)",
					toolbox: {
						show: false,
					},
					legend: {
						show: false,
					},
					grid: [
						{
							left: "20",
							right: "20",
							top: "10",
							bottom: "20",
							// containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],
					yAxis: {
						type: "value",
						axisLine: {
							show: false,
						},
						show: false,
					},
				},
				echart1_opts: {
					//图例
					legend: {
						//图例
						// data:[],如果没有指定字符串数组自动从series获取
						type: "scroll", //plain普通图例、scroll滚动图例
						show: true,
						orient: "horizontal", //布局朝向：horizontal、vertical
						left: "left", //容器左侧的距离:像素值、百分比、或'left', 'center', 'right'
						top: "bottom", //容器顶部的距离:'top', 'middle', 'bottom'
						selectedMode: true, //是否可以通过点击图例改变系列的显示状态
						textStyle: {
							color: "#ffffff",
						},
					},
					toolbox: {
						show: false,
					},
					grid: [
						{
							left: "20",
							right: "20",
							top: "10",
							bottom: "50",
							// containLabel: true, //绘图区域是否包含坐标轴标签
						},
					],
					yAxis: {
						type: "value",
						axisLine: {
							show: false,
						},
						show: false,
					},
				},
				echart8_opts: {},
			};
		},
		// created() {

		// },
		mounted() {
			// // 获取浏览器可视区域高度
			// this.clientHeight = `${document.documentElement.clientHeight}`; //document.body.clientWidth;
			// window.onresize = function temp() {
			// 	this.clientHeight = `${document.documentElement.clientHeight}`;
			// };
			this.get_index();
		},
		components: {
			indexmap,
			mechart,
			mechartpie,
		},
		methods: {
			to_factory(row) {
				// console.log(row);
				this.company_id = row[7];
				// setTimeout(() => {
				this.get_index();
				// }, 200);
			},
			get_index() {
				request({
					url: "/admin/Stat/index",
					data: {
						company_id: this.company_id,
					},
				}).then((ret) => {
					if (ret.code == 1) {
						this.total = ret.data.total;
						this.rank_device_date = ret.data.rank_device_date;
						this.rank_device_month = ret.data.rank_device_month;
						// ret.data.echart0_opts.xAxis.show = false;
						// ret.data.echart1_opts.xAxis.show = false;
						ret.data.echart0_opts.xAxis.axisLabel = { color: "#ffffff" };
						ret.data.echart0_opts.xAxis.axisLine = {
							show: false,
							lineStyle: { color: "#ffffff" },
						};
						this.echart0_opts = ret.data.echart0_opts;
						ret.data.echart1_opts.xAxis.axisLabel = { color: "#ffffff" };
						ret.data.echart1_opts.xAxis.axisLine = {
							show: false,
							lineStyle: { color: "#ffffff" },
						};
						this.echart1_opts = ret.data.echart1_opts;
						// console.log(JSON.stringify(this.echart0_opt));

						// this.echart8_opts = {
						// 	title: {
						// 		show: true,
						// 		text: "{a|总电量(kWh)}\n{x|" + ret.data.echart8[3].value + "}",
						// 		x: "center",
						// 		y: "70",
						// 		textStyle: {
						// 			fontSize: "12",
						// 			fontWeight: "normal",
						// 			rich: {
						// 				a: {
						// 					color: "#fff",
						// 					lineHeight: 24,
						// 				},

						// 				x: {
						// 					color: "#fff",
						// 					fontSize: 24,
						// 				},
						// 			},
						// 		},
						// 	},
						// 	legend: {
						// 		// orient: "horizontal",
						// 		// bottom: 0,
						// 		show: false,
						// 	},
						// 	series: {
						// 		name: "",
						// 		type: "pie",
						// 		selectedMode: "single",
						// 		legendHoverLink: true, //是否启用图例 hover 时的联动高亮。
						// 		hoverAnimation: true, //是否开启 hover 在扇区上的放大动画效果。
						// 		radius: ["55%", "100%"],
						// 		avoidLabelOverlap: true,
						// 		hoverAnimation: false,
						// 		label: {
						// 			formatter: "{b}\n{d}%",
						// 			position: "inside",
						// 		},
						// 		data: [
						// 			ret.data.echart8[0],
						// 			ret.data.echart8[1],
						// 			ret.data.echart8[2],
						// 		],
						// 		left: 5,
						// 		right: 5,
						// 		top: 5,
						// 		bottom: 5,
						// 	},
						// };
					}
				});
			},
		},
	};
</script>